<template>
  <section>
  <div :style="getElementStyle"  v-if="!isFromDocument && data && data.properties &&  data.properties.filed_content !== 'Hide'">
    
    <el-row>
      <el-col
      v-if="!data.properties.hideLabel" :span="showLabel && !isDefalutPos ? 8 : data.description ? 20 : 24"
      >
        <span>
          <label
            for="text"
            v-if="hasLabel && !fromRepeatable"
            :style="getLabelStyles"
             :class="{'highlight-border': highlight}"
            >{{ data.label }}
          </label>
          <span
            v-if="data.validations.required && hasLabel && !isView"
            style="color: red; font-weight: bold"
          >
            *
          </span>
          <el-popover
          v-if="data.description"
          placement="top-start"
          trigger="hover"
          :content="data.description"
        >
            <i class="el-icon-info" slot="reference"></i
          >
        </el-popover>
        </span>
      </el-col>
      <el-col v-else :span="showLabel ? 4 : data.description ? 20 : 24" ref="colRef" :style="computedStyles">
        <span
            v-if="data.validations.required && hasLabel && !isView"
            style="color: red; font-weight: bold"
          >
            *
          </span>
            <span v-if="hasLabel" class="transparentcol"></span>
          </el-col>
      <el-col :span="showLabel && !isDefalutPos ? 12 : 24">
        <div v-if="!isView">
          <el-radio-group
            v-model="form[data.key]"
            @change="changeField"
            :disabled="isFromDocument ? isDisable : checkReadonly() || readonly()"
            class="Eradio"
          >
          <ol :class="data.view_type === true ? '' : 'horizontalView'">
              <li
              v-for="(option, index) of options"
                :key="index"
                :class="
                  data.view_type === true
                    ? 'radio-option'
                    : 'horizontalView radio-option'
                "
              >
            <el-radio
              :value="option"
              :label="option"
              :disabled="isFromDocument ? isDisable : checkReadonly() || readonly()" border
              >{{ option }}</el-radio
            >
          </li>
            </ol>
          </el-radio-group>
        </div>
      </el-col>
    </el-row>

    <p
      v-if="missingKeys.includes('' + data.key)"
      style="color: red; font-size: 14px"
    >
      {{ data.label }} is Required
    </p>
    <p v-if="isView">
      <template v-if="form && form[data.key]"> {{ form[data.key] }} </template>
      <template v-else>-</template>
    </p>
  </div>
  <div :style="getElementStyle"  v-if="isFromDocument">
    
    <el-row>
      <el-col
        :span="showLabel && !isDefalutPos ? 8 : data.description ? 20 : 24"
      >
        <span>
          <label
            for="text"
            :style="getLabelStyles"
            >{{ data.label }}
          </label>
          <span
          v-if="data.validations.required && !isView"
          style="color:red;font-weight:bold;"
        >
          *
        </span>
        </span>
      </el-col>

      <el-col :span="data.description ? 4 : 0">
        <el-popover
          v-if="data.description"
          placement="top-start"
          title="Help"
          trigger="click"
          :content="data.description"
        >
          <span style="padding-left: 10px">
            <i class="el-icon-info" slot="reference"></i
          ></span>
        </el-popover>
      </el-col>
      <el-col :span="showLabel && !isDefalutPos ? 12 : 24">
        <div v-if="!isView">
          <el-radio-group
            v-model="data.value"
            @change="changeField"
            :disabled="isFromDocument ? isDisable : checkReadonly() || readonly()"
          >
            <el-radio
              v-for="(option, index) of options"
              :value="option"
              :key="index"
              :label="option"
              :disabled="isFromDocument ? isDisable : checkReadonly() || readonly()"
              >{{ option }}</el-radio
            >
          </el-radio-group>
        </div>
      </el-col>
    </el-row>

    <p
      v-if="missingKeys.includes('' + data.key)"
      style="color: red; font-size: 14px"
    >
      {{ data.label }} is Required
    </p>
    <p v-if="isView">
      <template v-if="form && form[data.key]"> {{ form[data.key] }} </template>
      <template v-else>-</template>
    </p>
  </div>
</section>
</template>

<script>
import userPermissionsHelper from "@/mixins/userPermissionsHelper";

export default {
  mixins: [userPermissionsHelper],
  components: {},
  props: [
    "data",
    "form",
    "fromRepeatable",
    "isView",
    "hasLabel",
    "isFromDocument",
    "isDisable",
    "checkIsDisabled",
    "checkhideFields",
    "colorFields",
    "highlight"
  ],
  data() {
    return {
      validations: [],
      options: [],
      missingKeys: [],
      showLabel: true,
      isDefalutPos: true,
      localValue: ""
    };
  },
  computed: {
    getElementStyle() {
      let borderStyle = "";
      if (this.data.styles) {
        let type = this.data.styles.border_type
          ? this.data.styles.border_type
          : "solid";
        let size = this.data.styles.border_size
          ? this.data.styles.border_size + "px"
          : "0px";
        let color = this.data.styles.border_color
          ? this.data.styles.border_color
          : "";
        let font_size =
          this.data.styles &&
          this.data.styles.font &&
          this.data.styles.font.font_size
            ? this.data.styles.font.font_size
            : "";
        let bold =
          this.data.styles &&
          this.data.styles.font &&
          (this.data.styles.font.style == 1 || this.data.styles.font.style == 3)
            ? "bold"
            : "";
        let italic =
          this.data.styles &&
          this.data.styles.font &&
          (this.data.styles.font.style == 2 || this.data.styles.font.style == 3)
            ? "italic"
            : "";

        borderStyle = "border:" + type + " " + " " + size + " " + color;

        borderStyle += ";";
        borderStyle += this.data.styles.background
          ? `background: ${this.data.styles.background}`
          : "";
        borderStyle += ";";
        borderStyle += this.data.styles.label_color
          ? `color: ${this.data.styles.label_color}`
          : "";
        borderStyle += ";";
        borderStyle += "font-size:" + font_size + "px;";
        if (bold) {
          borderStyle += "font-weight:" + bold + ";";
        }
        if (italic) {
          borderStyle += "font-style:" + italic + ";";
        }
        borderStyle += this.data.styles.label_color
          ? `color: ${this.data.styles.label_color}`
          : "";
      }
      return borderStyle;
    },
    getStyle() {
      return this.hasLabel
        ? `height:${this.data.height - 5}px;width:${this.data.width - 5}px;`
        : "";
    },
    getLabelStyles() {
      return this.data.styles && this.data.styles.label_color
        ? `color: ${this.data.styles.label_color}; `
        : "font-weight: 700";
    },
    computedStyles() {
    const styles = {};
    styles.display = 'flex';
    if (this.data?.styles?.labelAlignments) {
      styles.justifyContent = this.data.styles.labelAlignments;
    }
    return styles;
    },
  },
  created() {},
  mounted() {
    this.options = this.data.options;
    
    // if (this.isFromDocument) {
    //   this.form[this.data.key] = this.data.value;
    // }

    //if (!this.form[this.data.key]) {
    //  this.$set(this.form, this.data.key, this.data.default_value);
      // this.form[this.data.key]=this.data.default_value
    //}

    
  },
  methods: {
    changeField(val) {
      this.$set(this.form, this.data.key, val);
      this.$forceUpdate();
      this.applyFormRules();
    },
    applyFormRules() {
      this.$emit("applyFormRules");
    },
    readonly() {
      if (
        this.data &&
        this.data.properties &&
        this.data.properties.filed_content === "READ ONLY"
      ) {
        return true;
      }
    },
    checkReadonly() {
      if (this.checkIsDisabled) {
        return true;
      }
      if (this.fieldFilledByCurrentUser(this.data.filled_by) === "false") {
        if (this.data.styles && this.data.styles.labelStyle) {
          if (this.data.styles.labelStyle == "hide") {
            this.showLabel = false;
          }
          if (this.data.styles.labelStyle == "right") {
            this.isDefalutPos = false;
          }
        }
        return !this.fieldFilledByCurrentUser(this.data.filled_by);
      } else {
        if (
          this.data &&
          this.data.properties &&
          this.data.properties.filed_content === "DISABLED"
        ) {
          return true;
        }
        return !this.fieldFilledByCurrentUser(this.data.filled_by);
      }
    },

    valueChange() {
      // bus.$emit("updatedActionFormData", this.form);
      // bus.$emit("updatedFormData", this.form);
      // if (this.form[this.data.key]) {
      //   let formIndex = this.missingKeys.findIndex((e) => e === this.data.key);
      //   this.missingKeys.splice(formIndex, 1);
      // }
      this.$set(this.form, this.data.key, !this.form[this.data.key]);
    },
  },
  watch: {
    isFromDocument: {
      deep: true,
      immediate: true,
      handler() {
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.highlight-border{
  color: #ff4040 !important;
    }
.radio-option {
  margin-bottom: 10px; /* Adjust the space between options at the bottom */
}

.horizontalView {
  display: inline-block;
  margin-right: 10px;
  padding: 0px;
}

.transparentcol{
  color: white; 
  display: inline-block; 
  height: 20px; 
  opacity: 0;
}
</style>
